import { useState, useEffect } from 'react';
// @ts-expect-error the path is unkown because it belongs to the federated module
import cartMFEVersion from 'cart/version';
// @ts-expect-error the path is unkown because it belongs to the federated module
import checkoutMFEVersion from 'checkout/version';
// @ts-expect-error the path is unkown because it belongs to the federated module
import subscriptionMFEVersion from 'subscription/version';
// @ts-expect-error the path is unkown because it belongs to the federated module
import ordersMFEVersion from 'orders/version';
import { loadShare } from '@module-federation/enhanced/runtime';

type MfeVersionsType = {
  cartVersion: string;
  checkoutVersion: string;
  subscriptionVersion: string;
  contextManagerVersion: string;
  ordersVersion: string;
};

export const useMfeVersion = (): MfeVersionsType => {
  const [cartVersion, setCartVersion] = useState<string>('');
  const [checkoutVersion, setCheckoutVersion] = useState<string>('');
  const [subscriptionVersion, setSubscriptionVersion] = useState<string>('');
  const [contextManagerVersion, setContextManagerVersion] = useState<string>('');
  const [ordersVersion, setOrdersVersion] = useState<string>('');

  useEffect(() => {
    setCartVersion(cartMFEVersion);
    setCheckoutVersion(checkoutMFEVersion);
    setSubscriptionVersion(subscriptionMFEVersion);
    getContextManagerVersionloadShare();
    setOrdersVersion(ordersMFEVersion);
  }, []);

  const getContextManagerVersionloadShare = async () => {
    const contextManagerFactory = await loadShare('@rc-d2c/context-manager');
    if (contextManagerFactory) {
      const contextManager = contextManagerFactory() as Partial<{ VERSION: string }>;
      if (contextManager?.VERSION) {
        setContextManagerVersion(contextManager.VERSION ?? '');
      }
    }
  };

  return { cartVersion, checkoutVersion, subscriptionVersion, contextManagerVersion, ordersVersion };
};
